import { navigate } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"

import { useUser } from "../auth/hooks"
import { PageHeader, PageNotificationBanner, LinkButton } from "../components"
import Seo from "../components/seo"

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.porcelain};
  height: 100vh;
  width: 100vw;
  padding: 60px 35px;

  h2 {
    font-family: ${({ theme }) => theme.fonts.title};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.body};
    font-weight: 500;
  }

  .buttonWrapper {
    display: flex;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 60px 70px;
  }
`

const scrollUp = keyframes`
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-120px);
  }
  20% {
    transform: translateY(-120px);
  }
  30% {
    transform: translateY(-240px);
  }
  40% {
    transform: translateY(-240px);
  }
  50% {
    transform: translateY(-360px);
  }
  60% {
    transform: translateY(-360px);
  } 
  70% {
    transform: translateY(-480px);
  }
  80% {
    transform: translateY(-480px);
  }
  90% {
    transform: translateY(-600px);
  }
  100% { 
    transform: translateY(-600px);
  }
`

const AnimatedHeader = styled.div`
  margin: 0px;
  height: 220px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;

  h1,
  p {
    font-family: ${({ theme }) => theme.fonts.title};
    font-size: 36px;
    font-weight: 900;
    line-height: 100px;
    margin-bottom: 0;
  }

  .exclaimation-text-wrapper {
    overflow: hidden;
  }

  .exclaimation-text {
    color: ${({ theme }) => theme.palette.outrageousOrange};
    animation: ${scrollUp} 6s ease forwards infinite;
  }

  .exclaimation-text-phrase {
    white-space: nowrap;
  }

  .exclaimation-text-phrase:not(:last-child) {
    padding-bottom: 20px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    h1,
    p {
      font-size: 72px;
      line-height: 100px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 20px 0px;
    height: 100px;
    flex-direction: row;
    gap: 20px;
  }
`

const exclaimations = [
  "Haibo!",
  "Ag, Nee!",
  "What kind?",
  "No, man!",
  "Eish!",
  "Haibo!",
]

const NotFoundPage = (): React.ReactElement => {
  const { user } = useUser()

  const handleNavigate = () => {
    if (user) {
      navigate("/dashboard/")
    } else {
      navigate("/sign-in/")
    }
  }

  return (
    <Wrapper>
      <Seo title="404: Not found" />
      <PageHeader title="..." />
      <PageNotificationBanner>
        <p>Error: 404!</p>
      </PageNotificationBanner>
      <AnimatedHeader>
        <h1>404: </h1>
        <div className="exclaimation-text-wrapper">
          <div className="exclaimation-text">
            {exclaimations.map((phrase, index) => (
              <p
                key={`${phrase}-${index}`}
                className="exclaimation-text-phrase"
              >
                {phrase}
              </p>
            ))}
          </div>
        </div>
      </AnimatedHeader>
      <h2>Something went wrong!</h2>
      <p>The page you are looking for cannot be found.</p>
      <div className="buttonWrapper">
        <LinkButton color="black" padding="0px" onClick={handleNavigate}>
          {user ? "Back to Dashboard" : "Back to Sign In"}
        </LinkButton>
      </div>
    </Wrapper>
  )
}

export default NotFoundPage
